"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isCentralRetailer;
var _useChangeReleaseFlag = require("../utilities/useChangeReleaseFlag");
// add CentralRetailer's id here

// non prod
var NonProdCentralRetailer = ['130267', '130291', '130292'];
// prod
var ProdCentrailRetailers = ['130267', '130293', '130294'];
function isCentralRetailer(id) {
  var env = (0, _useChangeReleaseFlag.getEnviromentFromUrl)();
  var isProd = env === 'prod';
  // check if the id is in the list of CentralRetailer's id
  return (isProd ? ProdCentrailRetailers : NonProdCentralRetailer).includes(id);
}